body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .App {
    text-align: center;
  }
  
  nav ul {
    list-style-type: none;
    padding: 0;
  }
  
  nav ul li {
    display: inline;
    margin: 0 10px;
  }